/* stylelint-disable selector-max-compound-selectors */

@import '~@readme/react/src/core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.Param {
  --Param-color: var(--color-text-default);

  color: var(--Param-color);
  display: flex;
  padding: $sm;

  @media (max-width: $ipad-pro-10-portrait) and (min-width: $ipad-mini-portrait + 1) {
    flex-direction: column;
  }

  @media (max-width: $iphone-plus) {
    flex-direction: column;
  }

  // collapse long key names into ellipses and keep it from pushing other content around
  &-left {
    flex: 0 1 auto;
    min-width: 0;

    .Param-header {
      flex-wrap: nowrap;
    }

    .Param-name {
      max-width: calc(100% - 100px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:only-child {
        max-width: 100%;
      }
    }
  }

  &-left + form {
    flex: 1;
  }

  // nameless template stuff (top-level)
  &_topLevel > .Fieldset {
    width: 100%;

    // keep the padding if there’s more nesting
    .Param {
      margin: 0;
    }

    // remove double padding if direct descendent
    > .Param {
      &:only-child {
        margin: -$sm;
      }

      &:not(:only-child) {
        margin-left: -$sm;
        margin-right: -$sm;

        &:first-child {
          margin-top: -$sm;
        }

        &:last-child {
          margin-bottom: -$sm;
        }
      }
    }
  }

  &-additionalProperties {
    align-items: center;
    background: none;
    color: var(--color-border-minimum);
    display: flex;
    height: 30px;
    text-align: left;
    width: 100%;

    &-label {
      font-size: 12px;
      font-weight: var(--font-weight-bold);
      overflow: hidden;
      padding-left: 6px;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  + .Param {
    border-top: 1px solid var(--color-border-default);
  }

  + .ParamAdditional {
    margin-top: -$sm;
  }

  // different nesting @ level 3
  .Collapsed {
    .Param-expand_expanded {
      margin-bottom: -1px;
      margin-left: -1px;
      margin-right: -1px;
      padding-bottom: 0;
    }

    .Param-expand {
      margin-left: 0;
      margin-right: 0;
    }

    .Param-multischema {
      margin-left: $sm;
      margin-right: $sm;

      button {
        padding: 10px;
      }
    }

    .Param-form {
      margin-right: $sm;
    }
  }

  // Primarily used for multischemas (polymorphism) in object properties
  // Sometimes it doesn't have the Param-multischema class (http://localhost:7070/reference/post_anything-nested-one-of-object-with-nested-one-of?oas=polymorphism.json)
  .Param-multischema,
  .AccordionMultiSchema-option {
    .Param-expand-button {
      border-radius: 0;
    }

    .Param-expand {
      border-radius: 0;

      + .Param-expand {
        margin-top: calc(var(--border-width) * -1);
      }

      &:first-of-type:not(:last-child) {
        margin-bottom: 0;
      }

      &:first-child,
      &:first-of-type,
      &:first-child .Param-expand-button,
      &:first-of-type .Param-expand-button {
        border-top-left-radius: var(--border-radius-lg);
        border-top-right-radius: var(--border-radius-lg);
      }

      &:last-child,
      &:last-of-type,
      &:last-child:not(.Param-expand_expanded) .Param-expand-button,
      &:last-of-type:not(.Param-expand_expanded) .Param-expand-button {
        border-bottom-left-radius: var(--border-radius-lg);
        border-bottom-right-radius: var(--border-radius-lg);
      }

      &:only-child,
      &:only-child .Param-expand-button {
        border-radius: var(--border-radius-lg);
      }

      &:last-child,
      &:only-child {
        .Param-expand-button_expanded {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      // ignore :last-child border-radius if there’s a description
      .AccordionMultiSchema-description + .Param-expand:last-child {
        border-top-left-radius: var(--border-radius-lg);
        border-top-right-radius: var(--border-radius-lg);
      }

      // always bring hover/active/focus state element to the top
      .Param-expand-button:hover,
      .Param-expand-button:active,
      .Param-expand-button:focus {
        position: relative;
        z-index: 1;
      }
    }
  }

  &-expand,
  &-additionalProperties {
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius-lg);

    &:not(:first-child) {
      margin-top: $sm;
    }

    // http://localhost:7070/reference/post_anything-nested-one-of-object-with-nested-one-of?oas=polymorphism.json
    & > .Param-expand {
      margin-top: 0;
    }

    &.Param-multischema {
      border: 0;
      margin-top: $sm;
    }

    &:not(:first-child):not(:first-of-type) {
      margin-top: $sm;
    }

    &-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-button {
      align-items: center;
      background: none;
      border: 0;
      border-radius: var(--border-radius-lg);
      color: var(--color-border-minimum);
      cursor: pointer;
      font-family: var(--font-family);
      font-size: 12px;
      font-weight: var(--font-weight-bold);
      height: 30px;
      justify-content: space-between;
      text-align: left;
      text-transform: uppercase;
      transition: box-shadow var(--transition-fast) var(--transition-timing);
      width: 100%;

      + .Param-children,
      + .Param {
        border-top: var(--border-width) solid var(--color-border-default);
      }

      &:hover,
      &:active {
        // borders using box-shadow, b/c .Param-expand already has a border
        box-shadow: 0 0 0 1px var(--color-border-default);
      }

      &:focus {
        border-color: $blue;
        box-shadow:
          0 0 0 1px var(--color-input-border-active),
          0 0 0 3px var(--color-input-border-focus);
        outline: 0;
        position: relative;
        z-index: 1;
      }

      &_expanded {
        border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;

        &:hover,
        &:active,
        &:focus {
          .icon-x {
            color: var(--red);
          }
        }

        .icon {
          transform: rotate(45deg);
        }
      }

      &-icon {
        $icon-padding: 2px;

        align-items: center;
        background: none;
        border: 0;
        border-radius: var(--border-radius);
        color: var(--color-text-default);
        cursor: pointer;
        display: inline-flex;
        height: $icon-md + $icon-padding;
        justify-content: center;
        margin-left: $xs;
        padding: $icon-padding;
        transition: transform var(--transition-slow) var(--transition-timing);
        width: $icon-md + $icon-padding;

        &:hover,
        &:active,
        &:focus {
          @include dark-mode {
            background: rgba(white, 0.05);
          }

          background: rgba(black, 0.05);
        }

        &:focus {
          outline: 0;
        }

        &_trash {
          color: $red;

          &:hover,
          &:active,
          &:focus {
            background: rgba($red, 0.1);
          }
        }
      }
    }
  }

  &-header {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;

    &:only-child {
      line-height: 29px;
      min-height: 30px;
    }
  }

  &-left {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
  }

  &-name,
  &-required,
  &-type {
    &:not(:last-child):not(:only-child) {
      margin-right: $xs;
    }
  }

  &-name {
    flex: none;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
  }

  &-deprecated {
    align-self: center;
  }

  &-required,
  &-type {
    font-size: 12px;
    white-space: nowrap;
  }

  &-required {
    @include dark-mode {
      color: var(--red);
    }

    color: darken($red, 15%);
  }

  &-default-label,
  &-minmax-label {
    font-size: 13px;
    margin-right: $xs;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-type,
  &-default-label,
  &-minmax-label {
    color: var(--color-text-minimum);
  }

  &-description {
    margin-top: $xs;

    :global([href]) {
      color: inherit;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }

    :global(.markdown-body) {
      pre,
      code {
        --md-code-background: var(--gray90);

        @include dark-mode {
          --md-code-background: var(--gray10);
        }
      }
    }
  }

  // aka 'right'
  &-form {
    flex: 0 0 auto;
    width: 100%;

    // there’s no form for params in the response modal; this allows it to collapse
    :global(.response) & {
      width: unset;
    }

    @media (min-width: $ipad-pro-10-portrait + 1), (max-width: $ipad-mini-portrait) and (min-width: $iphone-plus + 1) {
      margin-left: $sm;
      max-width: var(--param-form-width);

      // there’s no form for params in the response modal; this allows it to collapse
      :global(.response) & {
        margin-left: 0;
      }
    }

    @media (max-width: $ipad-pro-10-portrait) and (min-width: $ipad-mini-portrait + 1), (max-width: $iphone-plus) {
      margin-top: $sm;

      // there’s no form for params in the response modal; this allows it to collapse
      :global(.response) & {
        margin-top: 0;
      }
    }
  }

  /* Doc Gen extension—exclusive to Amazon */
  &-enum-descriptions {
    --enum-descriptions-border: 1px solid var(--color-border-default);
    --enum-descriptions-font-size: 13px;

    $enum-descriptions: &;

    /* <detail /> */
    &[open] {
      #{$enum-descriptions}-icon {
        transform: rotate(90deg);
      }
    }

    &-icon {
      transition: transform var(--transition-slow) var(--transition-timing);
    }

    &-summary {
      align-items: center;
      color: var(--color-text-minimum);
      cursor: pointer;
      display: flex;
      font-size: 12px;
      font-weight: var(--font-weight-bold);
      list-style: none;
      margin-top: var(--sm);
      text-transform: uppercase;

      &:hover,
      &:active,
      &:focus {
        color: var(--color-text-muted);
      }

      &::-webkit-details-marker {
        display: none;
      }
    }

    &-table {
      $enum-descriptions-table: &;

      /* specificity needed to override <Table /> */
      &#{$enum-descriptions-table}#{$enum-descriptions-table} {
        border: var(--enum-descriptions-border);
        border-radius: var(--border-radius);
        height: auto;
        margin-top: var(--xs);

        td {
          color: var(--color-text-default);
          font-size: var(--enum-descriptions-font-size);
          white-space: normal;

          // overriding ReactTable styles
          &:nth-child(n + 2) span {
            white-space: normal;
            word-break: normal; /* safari + firefox */
            word-break: auto-phrase; /* blink */
          }
        }
      }
    }
  }
}

.Flex_grow {
  text-transform: uppercase;
}

.DeprecatedAccordion {
  border: 0;
  border-top: var(--border-width) solid var(--color-border-default);
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .Param-expand-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &-badge {
    background: none;
    border: var(--border-width) solid var(--color-border-default);
    color: var(--color-text-minimum);
  }

  &-header {
    color: var(--color-text-minimum);
    justify-content: space-around;
    overflow: hidden;

    &.Param-expand-button_expanded {
      justify-content: space-between;
    }
  }
}

.ParamAdditional {
  padding: $sm;

  .Param-expand-button {
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius-lg);
  }

  &-label {
    flex: 1 1 auto;

    + .ParamAdditional-input {
      width: auto;
    }
  }

  &-input {
    max-width: var(--param-form-width);
  }

  // Since these won't have a label
  .Param-required,
  .Param-type {
    &:first-child {
      margin-left: 0;
    }
  }
}

// kill the top level element for multischema selectors in response docs
:global(form.response) {
  .Param-response-array {
    font-variant: small-caps;
  }
}

:global(.rm-ParamContainer.multischema) {
  background: var(--color-bg-page);
  border: 0;

  :global(section.even) {
    @include dark-mode {
      background: var(--gray15);
    }

    background: var(--gray100);
  }

  :global(section.odd) {
    background: var(--color-bg-page);
  }

  > div {
    section,
    > section,
    > section .Param-expand-button {
      border-radius: 0;
    }

    > section:first-child,
    section:first-of-type,
    > section:first-child .Param-expand-button,
    section:first-of-type .Param-expand-button {
      border-top-left-radius: var(--border-radius-lg);
      border-top-right-radius: var(--border-radius-lg);
    }

    > section:last-child,
    section:last-of-type,
    > section:last-child .Param-expand-button,
    section:last-of-type .Param-expand-button {
      border-bottom-left-radius: var(--border-radius-lg);
      border-bottom-right-radius: var(--border-radius-lg);

      &.Param-expand-button_expanded {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    > section:not(:first-child):not(:first-of-type) {
      border-top: 0;
      margin-top: 0;
    }
  }
}

.AccordionMultiSchema {
  &-option section {
    margin: 10px;
  }

  &-header {
    align-items: center;
    background: none;
    border: 0;
    color: var(--color-text-minimum);
    font-weight: var(--font-weight-bold);
    justify-content: space-between;
    padding: $md $sm $md $sm;
    text-align: left;
    transition: box-shadow var(--transition-fast) var(--transition-timing);
    width: 100%;

    :global(.icon) {
      color: var(--color-text-minimum);
    }
  }

  &-details {
    flex: 1 1 auto;
    font-size: 12px;
  }

  &-label {
    color: var(--color-text-minimum);
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &-description {
    border-top: var(--border-width) solid var(--color-border-default);
    color: var(--color-text-minimum);
    padding: $sm 0 0 $sm;

    &:empty {
      padding: 0;
    }
  }
}
